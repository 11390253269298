import React from "react";
import theme from "theme";
import { Theme, Image, Text, Button, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Тренажерний зал Drift Brix
			</title>
			<meta name={"description"} content={"Зробіть перший крок до того, щоб стати здоровішим і сильнішим, приєднавшись до Drift Brix Gym."} />
			<meta property={"og:title"} content={"Головна | Тренажерний зал Drift Brix"} />
			<meta property={"og:description"} content={"Зробіть перший крок до того, щоб стати здоровішим і сильнішим, приєднавшись до Drift Brix Gym."} />
			<meta property={"og:image"} content={"https://driftwaybrix.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://driftwaybrix.com/img/1616477.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://driftwaybrix.com/img/1616477.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://driftwaybrix.com/img/1616477.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://driftwaybrix.com/img/1616477.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://driftwaybrix.com/img/1616477.png"} />
			<meta name={"msapplication-TileImage"} content={"https://driftwaybrix.com/img/1616477.png"} />
		</Helmet>
		<Components.Header />
		<Section background="#f9f2f0" padding="0 0 0 0" inner-width="100%" quarkly-title="Stages/Steps-1">
			<Box min-width="100px" min-height="100px" display="flex" md-flex-direction="column">
				<Image
					src="https://driftwaybrix.com/img/1.jpg"
					display="block"
					width="45%"
					height="100%"
					object-fit="cover"
					md-width="100%"
					md-height="500px"
					sm-height="400px"
				/>
				<Box
					min-width="100px"
					min-height="100px"
					width="55%"
					padding="70px 70px 70px 70px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="center"
					lg-padding="50px 50px 50px 50px"
					md-width="100%"
					md-padding="80px 50px 80px 50px"
					sm-padding="80px 25px 80px 25px"
				>
					<Text margin="0px 0px 20px 0px" font="normal 900 42px/1.2 --fontFamily-mono">
						Drift Brix
					</Text>
					<Text margin="0px 0px 30px 0px" font="normal 300 16px/1.5 --fontFamily-sansVerdana">
					Ласкаво просимо до тренажерного залу Drift Brix, де ваша фітнес-подорож досягає нових висот. У Drift Brix ми віримо в розширення можливостей кожної людини для досягнення повною мірою свого потенціалу. Незалежно від того, чи ви досвідчений спортсмен, чи тільки починаєте, наше сучасне обладнання та спільнота підтримки тут, щоб допомогти вам на кожному кроці.
					</Text>
					<Button
						background="--color-primary"
						color="--darkL1"
						border-width="2px"
						border-style="solid"
						border-color="--color-darkL1"
						padding="12px 30px 12px 30px"
						font="normal 300 16px/1.5 --fontFamily-mono"
						href="/contacts"
						type="link"
						text-decoration-line="initial"
					>
						Запланувати візит
					</Button>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" quarkly-title="Content-11" background="#f9f2f0">
			<Override
				slot="SectionContent"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				align-items="start"
				lg-grid-template-columns="1fr"
			/>
			<Box min-width="10px" min-height="10px" margin="0px 50px 0px 0px" lg-margin="0px 0 50px 0px">
				<Text
					margin="0px 0px 30px 0px"
					font="normal 600 42px/1.2 --fontFamily-sans"
					letter-spacing="8px"
					white-space="nowrap"
					color="--dark"
					sm-white-space="normal"
				>
					Чому Drift Brix?
				</Text>
				<Text
					margin="0px 0px 50px 0px"
					font="--lead"
					overflow-wrap="normal"
					word-break="normal"
					text-overflow="clip"
					hyphens="none"
					lg-margin="0px 0px 35px 0px"
				>
					Сучасне обладнання: ми пропонуємо новітні фітнес-технології та обладнання, щоб покращити ваші враження від тренувань.
					<br/><br/>
Експертний персонал: наші сертифіковані тренери та привітний персонал завжди готові надати поради, підтримку та мотивацію.
					<br/><br/>
Різноманітність занять: від йоги до високоінтенсивних інтервальних тренувань, наші різноманітні пропозиції занять гарантують, що кожен знайде щось для себе.
				</Text>
			</Box>
			<Box min-width="100px" min-height="100px" display="flex" sm-flex-direction="column">
				<Image src="https://driftwaybrix.com/img/2.jpg" display="block" margin="0px 35px 0px 0px" sm-margin="0px 0 30px 0px" />
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-5" background="#f9f2f0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				lg-align-items="center"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				lg-flex-direction="column"
				width="100%"
				flex-direction="row"
				align-items="flex-start"
				lg-width="100%"
			>
				<Text
					margin="0px 36px 00px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					width="30%"
					lg-width="100%"
					lg-margin="24px 0px 30px 0px"
					lg-order="1"
					md-text-align="left"
				>
					Зробіть перший крок до того, щоб стати здоровішим і сильнішим, приєднавшись до Drift Brix Gym. Наша привітна спільнота, найсучасніше обладнання та експертне керівництво тут, щоб підтримати вас у вашій фітнес-подорожі. Прийміть виклик, подолайте свої межі та відкрийте для себе силу в Drift Brix Gym.
				</Text>
				<Box
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 25% 0px"
					overflow-x="hidden"
					overflow-y="hidden"
					transition="transform 0.2s ease-in-out 0s"
					lg-padding="0px 0px 50% 0px"
					lg-width="100%"
					width="55%"
					height="auto"
				>
					<Image
						src="https://driftwaybrix.com/img/3.jpg"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						object-fit="cover"
						position="absolute"
						display="block"
						width="100%"
						left={0}
					/>
				</Box>
			</Box>
			<Box
				justify-content="center"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
				width="45%"
				lg-width="100%"
				align-items="flex-start"
			>
				<Box
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					overflow-y="hidden"
					position="relative"
					padding="0px 0px 150% 0px"
					lg-padding="0px 0px 100% 0px"
				>
					<Image
						object-fit="cover"
						display="block"
						top={0}
						lg-right="0px"
						lg-width="100%"
						bottom={0}
						min-height="100%"
						lg-left="-1px"
						src="https://driftwaybrix.com/img/4.jpg"
						position="absolute"
						width="100%"
						left={0}
						right={0}
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				align-items="flex-start"
				lg-align-items="center"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
				width="55%"
				lg-order="1"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 40% 0px"
				>
					<Image
						src="https://driftwaybrix.com/img/5.jpg"
						object-fit="cover"
						left={0}
						min-height="100%"
						position="absolute"
						display="block"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
					/>
				</Box>
				<Text
					lg-text-align="center"
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					md-text-align="left"
				>
					Ми тут, щоб відповісти на будь-які запитання, які у вас можуть виникнути, і допомогти вам розпочати свою фітнес-подорож. Зв’яжіться з нами, якщо ви хочете отримати докладнішу інформацію про наші пропозиції, запланувати консультацію чи просто оглянути наші заклади.
				</Text>
			</Box>
		</Section>
		<Components.Contacts />
		<Components.FooterMenu />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});